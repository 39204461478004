.prevInfo {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 10px 0;
    // border-bottom: 1px solid #CECECE;
    background: #D93A2F;
    color: white;

    .title {
        font-size: 18px;
        text-align: left;
        margin-left: 25px;
        width: 100%;

        @media only screen and (max-width: 640px) {
            margin-left: 2px;
        }
    }

    .row {
        width: 100%;
        display: flex;
        font-size: 14px;
        margin: 5px auto;
        justify-content: space-evenly;
        display: grid;
        grid-template-columns: auto auto auto auto auto;

        @media only screen and (max-width: 640px) {
            margin: 0 auto;
            align-items: center;
            flex-direction: column;
            display: grid;
            grid-template-columns: auto auto;
        }

        .nb {
            border: none !important;
        }

        .section {
            min-width: 200px;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            // background: #D93A2F;
            // color: white;
            flex-direction: row;
            border-radius: 5px;
            font-size: 16px;
            border: 1px solid #FFFFFF;

            .icon {
                display: flex;
                font-size: 35px;
                @media only screen and (max-width: 640px) {
                    font-size: 30px;
                }
            }
            .text {
                height: 100%;
                display: flex;
                font-size: 13px;
                flex-direction: column;
                justify-content: space-evenly;
                @media only screen and (max-width: 640px) {
                    font-size: 12px;
                }
            }

            @media only screen and (max-width: 640px) {
                min-width: 160px;
                width: 100%;
                margin: 5px auto;
            }
        }
    }
}