.innerContianer {
    width: 98%;
    height: 98%;
    margin: 1% auto;

    @media only screen and (max-width: 640px) {
        // width: 98vw;
        // height: 98vh;
        // overflow: scroll;
        // width: 140px;
    }
}

.welcomeText {
    font-weight: 500;
    text-align: left;
}

.subText {
    color: grey;
    font-size: 14px;
}

.headerText {
    text-align: left;
    font-weight: 500;
    font-size: 18px;
}

.primaryRow {
    width: 100%;
    display: flex;
    min-height: 36px;
    margin: 5px auto;
    align-items: center;
    justify-content: space-between;
    
    .searchBar {
        width: 20%;
        height: 24px;
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid grey;
    }
}