body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.textHeader {
  font-size: 18px;
  font-weight: 500;
}

// .MuiOutlinedInput-inputMarginDense {
//   padding-top: 5px !important;
//   padding-bottom: 5px !important;
// }

.MuiPaginationItem-root {
  color: #FFFFFF !important;
}

.MuiPaginationItem-textSecondary.Mui-selected {
  color: #D93A2F !important;
}

.MuiGrid-container {
  width: 50% !important;
}

.MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}

.MuiFormLabel-root {
  font-size: 0.75rem !important;
}

.MuiButton-root {
  font-family: sans-serif !important;
  text-transform: none !important;
}

.MuiMenu-paper {
  max-height: 300px !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  @media only screen and (max-width: 640px) {
    width: 0px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
