.logo-Container {
    height: 62px;
    width: 240px;
    cursor: pointer;
    background: #fff url(../../Content/gc_logo04_alt.png) no-repeat center center;
    @media only screen and (max-width: 640px) {
        width: 200px;
    }

    .App-logo {
        margin-top: 8px;
        margin-left: 2px;
        cursor: pointer;
        width: 50%;
    }
}

.storeDiv {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    padding: 0 10px;
}

.storeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
  
    .storeTitle {
      width: 100%;
      margin: 0 auto;
      text-align: left;
      font-weight: 500;
      font-size: 20px;
      font-family: sans-serif !important;
    }
  
    .storeSubTitle {
        width: 100%;
        @media only screen and (max-width: 1023px) {
            width: 90%;
        }
        margin: 0 auto;
        font-size: 12px;
        text-align: left;
        word-break: break-word;
    }
}

.searchResultShipment {
    width: 100%;
    display: flex;
    font-weight: 500;
    align-items: center;
    padding: 5px 0 5px 0;
    justify-content: space-around;
}



.profileContainer {
    display: flex;
    float: right;
    .profileName {
        margin: auto 0;
    }
    .profileMenu {
        margin: auto 0;
    }
}


.hvr-inner-spread {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
}
  
.hvr-inner-spread:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*background: #E16159;*/
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.hvr-inner-spread:hover, .hvr-inner-spread:focus, .hvr-inner-spread:active {
    color: #FFFFFF;
    font-weight: 600;
    // width: calc(100% - 20px);
    // margin-left: -3px;
    height: 62px;
    width: 240px;
    
    @media only screen and (max-width: 640px) {
        width: 200px;
    }
}

.hvr-inner-spread:hover:before, .hvr-inner-spread:focus:before, .hvr-inner-spread:active:before {
    background: #D93A2F url(../../Content/gc_logo05_alt.png) no-repeat center center;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}