.AuditTableContainer {
    // min-height: 480px !important;
    overflow: hidden;
    overflow-y: auto;
    color: black;

    @media only screen and (max-width: 640px) {
        overflow: auto;
    }
    
    .AuditTable {
        width: 100%;  
        border-collapse: collapse;
        width: 100%;

        td, th {
            padding: 8px;
            font-size: 12px;
            text-align: center;
        }
    
        th {
            font-size: 14px;
            background-color: #dcdcdc;
            border-bottom: 2px solid grey;
        }
        
        tr:nth-child(even) {
            background-color: #dcdcdc;
        }
        tr:nth-child(odd) {
            background-color: #FFFFFF;
        }
        
        .riicon {
            font-size: 20px;
            cursor: pointer;
        }
    }
}


.AuditFiltersContainer {
    padding: 5px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 640px) {
        flex-direction: column;
        align-items: center;
    }

    .chipFilters {
        display: flex;

        @media only screen and (max-width: 640px) {
            flex-direction: column;
            align-items: center;
        }
        
        .logType {
            width: 420px;
        }
        .appType {
            width: 160px;
        }
    }

    .pagination {
        height: 80px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        .buttons {
            display: flex;
            margin-left: 10px;
            align-items: center;

            .text {
                width: auto;
                margin: 0 5px;
                min-width: 100px;
            }
        }

        .dropdown {
            width: 60px;
            padding: 2px;
            margin-left: 5px;
            background: #eaeaea;
        }
    }
}
