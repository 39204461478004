.App {
  text-align: center;
}

.drawerDiv {
  display: flex;

  .drawer {
    width: 20%;
    min-width: 240px;
    max-width: 240px;
    background: #FFFFFF;
  }
  
  .renderComponent {
    width: 80%;
    background: #F3F3F4;
    min-width: calc(100% - 240px);
    max-width: calc(100% - 240px);
    @media only screen and (max-width: 640px) {
      width: 100vw;
      max-width: 100vw;
    }
  }
}

.UserError {
  margin-top: 50vh;
  @media only screen and (max-width: 640px) {
    margin-top: 35vh;
  }

  .title {
    font-size: 50px;
    color: #D93A2F;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subtitle {
    font-size: 25px;
  }
}

.rtoc {
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
}