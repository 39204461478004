.stepContainer {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    flex-direction: column;
}

.prevInfo {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 10px 0;
    // border-bottom: 1px solid #CECECE;
    background: #D93A2F;
    color: white;

    .title {
        font-size: 18px;
        text-align: left;
        margin-left: 25px;
        width: 100%;

        @media only screen and (max-width: 640px) {
            margin-left: 2px;
        }
    }

    .row {
        width: 100%;
        display: flex;
        font-size: 14px;
        margin: 5px auto;
        justify-content: space-evenly;
        display: grid;
        grid-template-columns: auto auto auto auto auto;

        @media only screen and (max-width: 640px) {
            margin: 0 auto;
            align-items: center;
            flex-direction: column;
            display: grid;
            grid-template-columns: auto auto;
        }

        .section {
            min-width: 200px;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            // background: #D93A2F;
            // color: white;
            flex-direction: column;
            border-radius: 5px;
            font-size: 16px;
            border: 1px solid #FFFFFF;

            @media only screen and (max-width: 640px) {
                min-width: 150px;
                width: 100%;
                margin: 5px auto;
            }
        }
    }
}

.prevInfoField {
    width: 25%;
}

.cardContainer {
    width: 100%;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    justify-content: space-evenly;

    @media only screen and (max-width: 640px) {
        flex-direction: column;
    }
    
    .cards {
        width: 35%;
        height: fit-content;
        cursor: pointer;
        // border: 1px solid white;
        border: 1px solid #D93A2F;
        box-shadow: 0px 2px 1px -1px rgba(217, 58, 47, 0.2), 0px 1px 1px 0px rgba(217, 58, 47, 0.14), 0px 1px 3px 0px rgba(217, 58, 47, 0.12);
        

        @media only screen and (max-width: 640px) {
            width: 90%;
            margin: 5px auto;
        }
    }
}

.iconHeader{
    font-size: 24px;
    font-weight: 500;
}

.iconRow {
    svg {
        font-size: 50px;
        color: #D93A2F;
    }
}

.iconRowDisabled {
    .svg {
        font-size: 50px;
        color:#f3837b;
    }
}

.shippingInfo {
    width: 100%;
    height: auto;
    display: flex;
    background: white;
    flex-direction: column;
    justify-content: center;
    
    @media only screen and (max-width: 640px) {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .innerDiv {
        height: 500px;
        display: flex;
        
        @media only screen and (max-width: 640px) {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    

        .column {
            width: 45%;
            height: 100%;
            display: flex;
            margin: 5px auto;
            flex-direction: column;
            justify-content: space-around;

            @media only screen and (max-width: 640px) {
                width: 100%;
                margin: 5px auto;
            }

            .header {
                font-size: 18px;
                text-align: left;
                font-weight: 500;
            }
            
            .row {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                @media only screen and (max-width: 640px) {
                    margin: 5px 0;
                }

                .fieldText {
                    width: 30%;
                    min-width: 105px;
                    text-align: left;
                    font-weight: 500;
                    @media only screen and (max-width: 640px) {
                        width: 40%;
                        margin: 5px 0;
                    }
                }

                .fieldInputFull {
                    width: 50%;
                }

                .fieldInputHalf {
                    width: 40%;
                    @media only screen and (max-width: 640px) {
                        width: 45%;
                    }
                }
            }
        }
    }
}

.serviceInfo {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: flex-start;;
    
    @media only screen and (max-width: 640px) {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .column {
        display: flex;
        width:45%;
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;

        @media only screen and (max-width: 640px) {
            width: 100%;
        }

        .row {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin: 5px 0;

            // @media only screen and (max-width: 640px) {
            //     margin: 5px 0;
            // }

            .fieldTitle {
                width: 150px;
                font-size: 14px;
                text-align: left;
                font-weight: 500;
            }

            .fieldCheck {
                width: 50%;
            }

            .fieldSelect {
                width: 250px;
                height: max-content;
                background: white;
                @media only screen and (max-width: 640px) {
                    width: 200px;
                }
            }
        }

        .radioCard {
            width: 50%;
            margin: 2px 0;
            display: flex;
            min-width: 240px;
            border-radius: 5px;
            align-items: center;
            padding: 5px 10px;
            flex-direction: column;
            border: 1px solid grey;

            .MuiFormControlLabel-label {
                font-size: 12px;
            }

            .PrivateSwitchBase-root-0 {padding: 2px !important;}
            .PrivateSwitchBase-root-1 {padding: 2px !important;}
            .PrivateSwitchBase-root-2 {padding: 2px !important;}
            .PrivateSwitchBase-root-3 {padding: 2px !important;}
            .PrivateSwitchBase-root-4 {padding: 2px !important;}
            .PrivateSwitchBase-root-5 {padding: 2px !important;}
            .PrivateSwitchBase-root-6 {padding: 2px !important;}
            .PrivateSwitchBase-root-7 {padding: 2px !important;}
            .PrivateSwitchBase-root-8 {padding: 2px !important;}
            .PrivateSwitchBase-root-9 {padding: 2px !important;}
            .PrivateSwitchBase-root-10 {padding: 2px !important;}
            .PrivateSwitchBase-root-11 {padding: 2px !important;}
            .PrivateSwitchBase-root-12 {padding: 2px !important;}
        }
    }
}

.review {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    justify-content: space-evenly;

    @media only screen and (max-width: 640px) {
        flex-direction: column;
        height: auto;
    }

    .column {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @media only screen and (max-width: 640px) {
            width: 100%;
        }
        
        .cardColor {
            border: 1px solid #D93A2F;
            box-shadow: 0px 2px 1px -1px rgba(217, 58, 47, 0.2), 0px 1px 1px 0px rgba(217, 58, 47, 0.14), 0px 1px 3px 0px rgba(217, 58, 47, 0.12);
        }

        .row {
            width: 80%;
            display: flex;
            margin: 5px auto;
            justify-content: space-evenly;

            .reviewCard {
                width: 100%;
                margin: 10px 0px;
                height: 200px !important;
                @media only screen and (max-width: 640px) {
                    height: auto !important;
                    min-height: 200px;
                }        
            }

            .label {
                width: 40%;
                text-align: right;
                @media only screen and (max-width: 640px) {
                    width: 49%;
                    text-align: left;
                }        
            }
            
            .value {
                width: 50%;
                text-align: left;
                @media only screen and (max-width: 640px) {
                    width: 50%;
                }
            }
        }

        .titleRow {
            width: 100%;
            display: flex;
            margin: 0 0 10px 0;
            justify-content: space-between;

            .header {
                font-size: 18px;
                text-align: left;
                font-weight: 600;
            }

            .edit {
                color: #D93A2F;
                cursor: pointer;
            }
            .edit:hover {
                border-bottom: 1px solid #D93A2F;
            }
        }
    }
}


.shiftButtons {
    display: flex;
    margin: 10px 0;
    align-items: flex-end;
    justify-content: center;
}


.SuccessMessage {
    margin: 0 0 20px 0;
    word-break: break-word;

    .SuccessRow { 
        display: flex;
        margin: 10px 0;
        font-size: 20px;
    
        .Title {
            margin: 0 15px 0 0;
            width: 175px;
            text-align: right;
            @media only screen and (max-width: 640px) {
                width: 100px;
            }
        }

        .Value {
            @media only screen and (max-width: 640px) {
                width: calc(100% - 100px);
            }
        }
    }
}

.fullWidthContainer{
    width:100% !important
}

.multiBoxCard{
    border: 1px solid #D93A2F;
    box-shadow: 0px 2px 1px -1px rgba(217, 58, 47, 0.2), 0px 1px 1px 0px rgba(217, 58, 47, 0.14), 0px 1px 3px 0px rgba(217, 58, 47, 0.12);
    border-radius: 5px;
    width: 95% !important
}

.editDetail{
    text-align: right;
    color: #D93A2F;
    cursor: pointer;
}
.editDetail:hover {
    border-bottom: 1px solid #D93A2F;
}

.success-toast{
    width: 250px;
}
