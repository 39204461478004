

.container {
  width: 99%;
  margin: 0 auto;
  margin-top: 15px;

  @media only screen and (max-width: 1023px) {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
  }
}


.controls {
  display: flex;
  justify-content: center;
  align-items: center;

  .controls_box {
    display:flex;
    width: 60vw;
    
    .controls_input {
      width: 100%;
      height: 24px;
      margin: 5px 0 0 0;
      padding: 5px 10px;
      border-radius: 20px;
      border: 1px solid grey;
    }
  }
}


.storeDetails {
  width: 100%;
  min-height: 75px;
  height: 128px;
  
  @media only screen and (max-width: 1023px) {
    height: auto;
  }
  
  .storeDetailHeader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border: None;
    justify-content: space-between;
    background: #e15241;
    color: white;

    @media only screen and (max-width: 1023px) {
      background: #f6dadd;
      border: 2px solid white;
      color: black;
    }
  }
}



// Filter Bar CSS 

.FilterBar {
  width: 100%;
  display: flex;
  /* padding: 10px 2px; */
  height: 45px;
  display: flex;
  margin: 5px auto;
  border-radius: 5px;
  background: #cccccc;
  align-items: center;

  .filter {
    width: 15%;
    margin: 0 5px;
    background: white;
  }

  .clearFilter {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  .clearFilter:hover {
    color: white;
    text-decoration: underline;
  }
}

.mobileCloseDiv {
  display: flex;
  justify-content: flex-end;

  .mobileCloseButton {
    margin: 10px;
    text-decoration: underline;
  }
}
