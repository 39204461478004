.DashboardContainer {
    min-height: 480px !important;
    overflow: hidden;
    // overflow-y: auto;
    color: black;

    @media only screen and (max-width: 640px) {
        overflow: auto;
    }

    .PrinterListContainer {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #bfbfbf;
        padding: 10px 0 0 0;

        .Header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 10px 0;
            
            .Title {
                display: flex;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
}

.createRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
}