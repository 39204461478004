.EODReportContainer {
    min-height: 480px !important;
    overflow: hidden;
    // overflow-y: auto;
    color: black;

    @media only screen and (max-width: 640px) {
        overflow: auto;
    }

    .selection {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 640px) {
            flex-direction: column;
        }
    }

    .carriers {
        width: 40%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        @media only screen and (max-width: 640px) {
            width: 100%;
            align-items: center;
        }
    }

    .w-adjust {
        width: 60%;

        @media only screen and (max-width: 640px) {
            width: 100%;
        }
    }
    
    .cardColor {
        border: 1px solid #D93A2F;
        box-shadow: 0px 2px 1px -1px rgba(217, 58, 47, 0.2), 0px 1px 1px 0px rgba(217, 58, 47, 0.14), 0px 1px 3px 0px rgba(217, 58, 47, 0.12);
    }
    
    .cardNormal {
        width: 60%;
        margin: 5px 0;
        cursor: pointer;
        
        @media only screen and (max-width: 640px) {
            height: auto;
            margin: 3px 0;
        }

        .Column {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .Title {
                display: flex;
                align-items: center;

                .radioButton {
                    width: 40%;
                    display: flex;
                    align-items: center;
                    color: #D93A2F;
                }
                .name {
                    width: 60%;
                }
            }
        }
    }

    .row {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        
        .title {
            min-width: 85px;
            color: #D93A2F;
            font-weight: 500;
            font-size: 18px;
            display: flex;
            align-items: center;
            text-align: left;
        }

        .rowPaper {
            padding: 20px 10px;
            display: flex;
            margin-top: 10px;
            align-items: center;
            flex-direction: column;
            width: 370px;
        }
    }

    .services {
        width: 100%;
        display: flex;
        margin-top: 10px;
        align-items: center;
        flex-direction: column;
    }
}



.EODTable {
    width: 100%;  
    border-collapse: collapse;
    width: 100%;

    td, th {
        padding: 8px;
        font-size: 12px;
        text-align: center;
    }

    th {
        font-size: 14px;
        background-color: #dcdcdc;
        border-bottom: 2px solid grey;
    }
    
    tr:nth-child(even) {
        background-color: #dcdcdc;
    }
    tr:nth-child(odd) {
        background-color: #FFFFFF;
    }
}

.report-logo-Container {
    width: 174px;
    height: 96px;
    background: #fff url(../../Content/GuitarCenterLarge.png) no-repeat center center;
    background-size: 174px 96px;
}