.LabelDiv {
    overflow: hidden;

    .LabelTableContainer {
        // min-height: 480px !important;
        overflow: hidden;
        overflow-y: auto;
        color: black;
    
        
        @media only screen and (max-width: 640px) {
            overflow: auto;
        }
    
        .LabelTable {
            width: 100%;  
            border-collapse: collapse;
            width: 100%;
            margin-bottom: 20px;

            .actions {
                display: flex;
                width: 70px;
                justify-content: space-between;
                align-items: center;
            }
        
            .faicon {
                font-size: 16px;
                cursor: pointer;
            }
    
            .carrierIcon {
                font-size: 22px;
            }
        
            .riicon {
                font-size: 20px;
                cursor: pointer;
            }

            .dis {
                color: grey;
            }
        
            td, th {
                padding: 8px;
                font-size: 12px;
                text-align: center;
            }
        
            th {
                font-size: 14px;
                background-color: #dcdcdc;
                border-bottom: 2px solid grey;
            }
            
            tr:nth-child(even) {
                background-color: #dcdcdc;
            }
            tr:nth-child(odd) {
                background-color: #FFFFFF;
            }
    
            .selected {
                color: #FFFFFF;
                background-color:#D93A2F !important;
            }
        }
    }
}

.hvr-bounce-to-right {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
}
  
.hvr-bounce-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*background: #E16159;*/
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.hvr-bounce-to-right:hover, .hvr-bounce-to-right:focus, .hvr-bounce-to-right:active {
    // color: #D93A2F;
    color: #FFFFFF;
    font-weight: 600;
    // margin-left: -3px;
}

.hvr-bounce-to-right:hover:before, .hvr-bounce-to-right:focus:before, .hvr-bounce-to-right:active:before {
    // background: #FFFFFF;
    background: #D93A2F;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}


.labelFilters {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    @media only screen and (max-width: 640px) {
        flex-direction: column;
    }

    .labelFilterSection {
        width: auto;
        max-width: 20%;
        min-width: 15%;

        @media only screen and (max-width: 640px) {
            width: auto;
            max-width: inherit;
            min-width: inherit;
        }
    }
    
    .statusFilterSection {
        max-width: 125px;
        min-width: 100px;
        width: auto;

        @media only screen and (max-width: 640px) {
            width: auto;
            max-width: inherit !important;
            min-width: inherit !important;
        }
    }
    
    .Title {
        font-size: 18px;
        color: #D93A2F;
        text-align: center;
        font-weight: 500;
    }

    .TitleMobile {
        display: none;
        @media only screen and (max-width: 640px) {
            display: block;
            font-size: 18px;
            color: #D93A2F;
            text-align: left;
            font-weight: 500;
        }
    }
}