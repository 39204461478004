.overlay {
    top: 0;
    left: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    min-height: 800px;
    position: absolute;
    background: #151515bf;

    .loader {
        width: fit-content;
        margin: 250px auto;
    }
}


.hvr-bounce-to-right {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
}
  
.hvr-bounce-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*background: #E16159;*/
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.hvr-bounce-to-right:hover, .hvr-bounce-to-right:focus, .hvr-bounce-to-right:active {
    // color: #D93A2F;
    color: #FFFFFF;
    font-weight: 600;
    // margin-left: -3px;
}

.hvr-bounce-to-right:hover:before, .hvr-bounce-to-right:focus:before, .hvr-bounce-to-right:active:before {
    // background: #FFFFFF;
    background: #D93A2F;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.names {
    z-index: 5;
    overflow: hidden;
    overflow-y: auto;
    max-height: 200px;
    position: absolute;
    background: white;
    border: 1px solid #CECECE;

    .name {
        padding: 2px 0;
        font-size: 14px;
        text-align: left;
        border-bottom: 1px solid #CECECE;

        .title {
            font-size: 16px;
            padding: 0 4px;
        }
    }
    .name:hover {
        cursor: pointer;
        background: #E5E5E5;
        
        .title {
            color: #D93A2F;
        }
    }

    .details {
        padding: 0 10px;
    }
}