.dialogButton {
    font-size: 16px !important;
}

.itemDialogBorder {
    border-bottom: 1px solid #dedada;
    width: 400px;
    @media only screen and (max-width: 640px) {
        width: 300px;
    }
}

.dialogHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.error {
    background: #D93A2F;
    color: white;
}

.info {
    background: #2f40d9;
    color: white;
}

.warning {
    background: #ff9800;
    color: white;
}

.confirm {
    background: #2196F3;
    color: white;
}

.dialogWidth {
    width: 400px;
    @media only screen and (max-width: 640px) {
        width: 300px;
    }
}

.labelDiv {
    display: flex;
    // width: 500px;
    justify-content: space-between;
}

.labelColumn {
    display: flex;
    font-size: 14px;
    flex-direction: column;

    .labelRow {
        width: 400px;
        margin: 5px 0;
        display: flex;
        word-break: break-word;
        justify-content: space-between;

        
        @media only screen and (max-width: 640px) {
            width: auto;
        }

        div {
            width: 48%;
        }

        div:nth-child(1) {
            font-weight: bold;
        }

        div:nth-child(2) {
            text-align: right;
        }
    }
}

.voidDiv {
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 640px) {
        width: 300px;
    }

    .voidActions {
        margin: 5px 0;
        display: flex;
        justify-content: center;
    }

    .voidLoader {
        margin: 15px 0;
    }
}

.voidLabel {
    font-size: 18px;
    font-weight: 500;
    word-break: break-word;
}

.inputField {
  text-align: left !important;

  p {
    text-align: left !important;
    margin-bottom: 3px !important;
    margin-top: 3px !important;
  }

  .MuiSelect-select {
    text-align: left !important;
  }

  .MuiFormControl-root {
    min-width: 100% !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .MuiFormControlLabel-root {
    margin-left: 0px !important;
  }
}